<template>
<section>
    <div class="col-12">
        <a @click="goBack()" class="pointer"><span class="back-detail"><i style='font-size:15px;transform: translateY(10%);' class='fas fa-chevron-left'></i> ย้อนกลับ </span></a>
    </div>
    
    <span style="
        margin: 0px;
        font-family: 'kittithadabold75regular';
        font-size: 1.5rem;
      ">จัดกลุ่มสินค้า
    </span>
    
    <a @click="showFormAddNewGroup()" style="color: gray" v-if="isShowAddNewGroup">เพิ่มกลุ่มสินค้า</a>
    <div class="row" style="margin-bottom: 10px" v-if="isShowPanelCreateNewGroup">
        <div class="col-md-3 col-12"></div>
        <div class="col-md-6 col-12">
            <input type="text" class="form-control text-center" ref="NewGroupCartName" style="margin-bottom: 10px" placeholder="กรุณาระบุชื่อกลุ่มของสินค้าที่ท่านต้องการสร้าง" autocomplete="off" v-model="newGroupName" />
            <a @click="createGroupCart()" :class="`font-color-${qtBu}`" style="float: left"><i class="fa fa-plus-circle" aria-hidden="true"></i> สร้างกลุ่ม</a>
            <a @click="hideCreateGroup()" style="color: gray; float: right"><i class="fa fa-ban" aria-hidden="true"></i> ยกเลิก</a>
        </div>
        <div class="col-md-3 col-12"></div>
    </div>
    <div class="row" v-if="isShowSaveCartGrouping">
        <div class="col-6 col-md-12">
            <!-- <a href="" style="float: left"><i class="far fa-save"></i> บันทึก</a> -->
            <span style='float:left'><PrintQuotation style='width: 100% !important' :cartref="qtCustRef" :stcode="qtDFStore" grouping="1" :groupName="sendGroupName"
            :sendTotPrice="sendTotPrice" :dlvcode="'temp'"/></span>
        </div>

        <!-- <div class="col-6 col-md-3">
            <a href="javascript:printDivVfix()" class="twdorbnb" style="float: left"><i class="fas fa-print"></i> พิมพ์แบบเต็ม</a>
        </div>
        <div class="col-6 col-md-3">
            <a href="javascript:printDivVfixCUSTOM()" class="twdorbnb" style="float: left"><i class="fas fa-print"></i> พิมพ์แบบกำหนดเอง</a>
        </div>
        <div class="col-6 col-md-3">
            <a href="javascript:printDivVfixCOM()" class="twdorbnb" style="float: left"><i class="fas fa-print"></i> พิมพ์แบบบริษัท</a>
        </div> -->
    </div>

    <div class="row">
        <div class="col-12 col-sm-12 col-md-6" id="main-groupcart-div" style="padding: 0px">
            <div class="kanban" id="main-groupcart-list">
                <div class="kanban-list" id="main-groupcart-item-list" v-on:drop="drop($event)" v-on:dragover="allowDrop($event)">
                    <div class="kanban-list-header" id="main-groupcart-header">
                        <span style="
                  margin: 0px;
                  font-family: 'kittithadabold75regular';
                  font-size: 30px;
                ">สินค้าทั้งหมด
                        </span>
                        <span style="
                  margin: 0px;
                  font-family: 'kittithadabold75regular';
                  font-size: 30px;
                  float: right;
                ">ราคารวม
                            <span style="
                    margin: 0px;
                    font-family: 'kittithadabold75regular';
                    font-size: 30px;
                  ">{{totalPriceMainGroup.toLocaleString()}}</span>
                            บาท
                        </span>
                    </div>
                    <div v-for="(e, index) in lstMainGroupCart" :key="index" :class="`kanban-item-container sub-item-${e.SEQ}`" :id="`item-${e.SEQ}`" draggable="true" v-on:dragstart="drag($event)" >
                      <span style='float:left;' >
                        <ImageBySKU :class='`img-${e.SKCODE}}-${e.SEQ} img-thumbnail`' style='margin:10px' :sku="e.SKCODE" draggable="false" width="100" height="100" />
                        <!-- <img class='img-${skcode}-${seq} img-thumbnail' style='margin:10px' draggable="false" width="100" height="100" src='' alt="${skcode}"  onError="this.src='../images/no-img.png';"> -->
                        </span>
                      <div class="kanban-item">
                        <h5 style='font-weight:bold;'>รหัสสินค้า <span style='font-weight:normal'>{{e.SKCODE}}</span></h5>
                        <h5 style='font-weight:bold;'>ชื่อสินค้า <span style='font-weight:normal'>{{e.PRN}}</span></h5>
                        <h5 style='font-weight:bold;'>จำนวน <span style='font-weight:normal'>{{e.QNT}} EACH</span> - ราคา <span style='font-weight:normal'><span style='font-weight:normal' class='numeric'>{{e.UPC}}</span> บาท</span></h5>
                        <h5 style='font-weight:bold;'>ราคารวม <span style='font-weight:normal'><span :id="`price-item-${e.SEQ}`" style='font-weight:normal' class='numeric'>{{e.TOT.toLocaleString()}}</span> บาท</span></h5>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12" id="sub-groupcart-div" style="padding: 0px">
            <div class="kanban">
                <div v-for="(e, index) in lstSubGroupCart" :key="index" class="kanban-list groupcart" :id="`groupcart-${e.seqGroup}`" v-on:drop="drop($event)" v-on:dragover="allowDrop($event)">
                    <div class="kanban-list-header">
                        <span style="
                  margin: 0px;
                  font-family: 'kittithadabold75regular';
                  font-size: 30px;
                ">{{ e.groupName }}
                        </span>
                        <span style="
                  margin: 0px;
                  font-family: 'kittithadabold75regular';
                  font-size: 30px;
                  float: right;
                ">ราคารวม
                            <span :id="`totalprice-sub-groupcart-${e.seqGroup}`" style="
                    margin: 0px;
                    font-family: 'kittithadabold75regular';
                    font-size: 30px;
                  ">0</span>
                            บาท
                            <a @click="removeGroupCart('groupcart-' + e.seqGroup)" :class="`font-color-${qtBu}`" style="
                    margin: 0px;
                    font-family: 'kittithadabold75regular';
                    font-size: 30px;
                  "><i class="fas fa-trash-alt"></i></a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import ImageBySKU from "@/components/ImageBySKU.vue";
import PrintQuotation from '@/components/Menu/PrintQuotation.vue';

export default {
    name: "CartGroupingMain",
    data: function () {
        return {
            isShowPanelCreateNewGroup: false,
            isShowAddNewGroup: true,
            isShowSaveCartGrouping: true,
            newGroupName: "",
            seqgroup: 0,
            lstSubGroupCart: [],
            lstMainGroupCart: [],
            totalPriceMainGroup: 0,
            dragged: null,
            draggedId: "",
            sendGroupName:'',
            sendTotPrice:''
        };
    },
    components: {
      ImageBySKU,
      PrintQuotation
    },
    mounted() {
      this.showgroupcart();
    },
    methods: {
        async showgroupcart() {
            try {
                let data = {
                    REF: this.qtCustRef,
                };
                let getMyCart = await serviceAPI.call_API(
                    "post",
                    `cart/getcart`,
                    data,
                    "auth"
                );
                if (getMyCart.data.dbcode > 0)
                    throw new Error(getMyCart.data.dbmessage);

                this.lstMainGroupCart = getMyCart.data.dbitems;
                this.totalPriceMainGroup = getMyCart.data.dbitems.reduce((prevVal, currVal) => {
                  return prevVal + parseFloat(currVal.TOT)
                }, 0)
            } catch (error) {
                serviceMain.showErrorAlert(this, error);
            }
        },
        async showFormAddNewGroup() {
            this.isShowAddNewGroup = false;
            this.isShowPanelCreateNewGroup = true;
            this.isShowSaveCartGrouping = false;
            setTimeout(() => this.$refs.NewGroupCartName.focus(), 500);
        },
        async createGroupCart() {
            ++this.seqgroup;
            if (this.newGroupName == "") {
                serviceMain.showWarningAlert(
                    this,
                    "กรุณาระบุชื่อกลุ่มสินค้าที่ท่านต้องการสร้าง"
                );
                return;
            }

            this.lstSubGroupCart.push({
                seqGroup: this.seqgroup,
                groupName: this.newGroupName,
            });
            this.sendGroupName = this.newGroupName
            this.newGroupName = "";
            this.hideCreateGroup();
        },
        async hideCreateGroup() {
            this.isShowAddNewGroup = true;
            this.isShowPanelCreateNewGroup = false;
            this.isShowSaveCartGrouping = true;
        },
        async removeGroupCart(groupcartnme) {
            await this.removeItemInGroupcart(groupcartnme);
            $(`#${groupcartnme}`).remove();
        },
        async removeItemInGroupcart(groupcartnme) {
            $("#" + groupcartnme)
                .find(".kanban-item-container")
                .each((idx, itm) => {
                    var iditmcart = $(itm).attr("id");
                    //change price main group
                    var priceitm = parseFloat(
                        $("#price-" + iditmcart)
                        .text()
                        .replaceAll(",", "")
                    );

                    var totpricemaingroup = this.totalPriceMainGroup
                    console.log(priceitm, totpricemaingroup);
                    this.totalPriceMainGroup = totpricemaingroup + priceitm
                    $("#main-groupcart-item-list").append($("#" + iditmcart));
                });
        },
        drag(e) {
            console.log(e.path[1])
            this.dragged = e.target;
            if (e.originalTarget) {
                //for firefox
                this.draggedId = $(e.originalTarget.parentElement).attr("id");
            } else {
                //for chrome
                this.draggedId = $(e.path[1]).attr("id");
            }
        },
        allowDrop(e) {
          e.preventDefault();
        },
        drop(e) {
            e.preventDefault();
            let parent;
            if (e.currentTarget) {
                // In Safari no path
                parent = e.currentTarget;
            } else if (e.originalTarget) {
                parent = e.originalTarget.parentElement;
            } else {
                // In chrome path
                parent = e.path.filter((i) => {
                    if (i.classList) {
                        return i.classList.contains("kanban-list");
                    }
                })[0];
            }

            parent.appendChild(this.dragged);
            var seqgroup = $(parent).attr("id");
            var seqitm, itemprice, totprice;
            if (this.draggedId == "main-groupcart-item-list") {
                if (seqgroup != "main-groupcart-item-list") {
                    seqitm = $(this.dragged).attr("id");
                    itemprice = parseFloat(
                        $("#price-" + seqitm)
                        .text()
                        .replaceAll(",", "")
                    );
                    totprice = parseFloat(
                        $("#totalprice-sub-" + seqgroup)
                        .text()
                        .replaceAll(",", "")
                    );
                    //change total price new group
                    $("#totalprice-sub-" + seqgroup).html(
                        parseFloat(totprice + itemprice).toLocaleString()
                    );

                    this.totalPriceMainGroup = this.totalPriceMainGroup - itemprice;
                    this.sendTotPrice = totprice + itemprice
                }
            } else {
                if (seqgroup != "main-groupcart-item-list") {
                    seqitm = $(this.dragged).attr("id");
                    itemprice = parseFloat(
                        $("#price-" + seqitm)
                        .text()
                        .replaceAll(",", "")
                    );
                    totprice = parseFloat(
                        $("#totalprice-sub-" + seqgroup)
                        .text()
                        .replaceAll(",", "")
                    );
                    //change total price new group
                    $("#totalprice-sub-" + seqgroup).html(
                        parseFloat(totprice + itemprice).toLocaleString()
                    );
                } else {
                    seqitm = $(this.dragged).attr("id");
                    itemprice = parseFloat(
                        $("#price-" + seqitm)
                        .text()
                        .replaceAll(",", "")
                    );
                    totprice = this.totalPriceMainGroup;
                    //change total price main group
                    this.totalPriceMainGroup = totprice + itemprice;
                    this.sendTotPrice = this.sendTotPrice - itemprice
                }
                $("#totalprice-sub-" + this.draggedId).html(
                    parseFloat(
                        parseFloat(
                            $("#totalprice-sub-" + this.draggedId)
                            .text()
                            .replaceAll(",", "")
                        ) - itemprice
                    ).toLocaleString()
                );
            }
        },
        async goBack() {
            window.history.go(-1);
            return false;
        },  
    },
    computed: {
        qtBu: function () {
            return this.$store.getters["customer/getQTBU"];
        },
        qtCustRef: function () {
            return this.$store.getters["customer/getQTCUSTREF"];
        },
        qtDFStore: function () {
            return this.$store.getters["service/getQTDFSTORE"]
        }
    },
};
</script>

<style lang="scss" scoped>
.kanban {
    display: grid;
}

.kanban-list {
    list-style: none;
    padding: 0 0 1em 0;
    display: inline-table;
    border: 1px solid #ffffff;
    margin: 0.625em;
    overflow-y: visible;
    border-radius: 8px;
    background-color: #ffffff;
}

.kanban-item-container {
    padding: 0.5em;
    display: block;
    min-width: 100%;
}

.kanban-list-header {
    padding: 0.625em 1em;
    border-bottom: 1px solid rgb(241, 241, 241);
}

.kanban-list-header h4,
.kanban-item h5 {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.kanban-item {
    padding: 0.5em;
    display: block;
    min-width: 100%;
    border: 1px solid rgb(241, 241, 241);
    background-color: rgb(241, 241, 241);
    color: #000;
}

a {
    cursor: pointer;

    &:hover {
        background-color: transparent;
        text-decoration: none;
    }
}
</style>

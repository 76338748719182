<template>
    <div class='container'>
        <CartGroupingMain />
    </div>
</template>

<script>
import CartGroupingMain from '@/components/CartGrouping/CartGroupingMain.vue'
export default {
    name: "CartGrouping",
    components: {
        CartGroupingMain
    },
    data: function () {
      return {

      }
    },
    mounted() {
        document.title = `${process.env.VUE_APP_TITLE} - Cart Grouping`
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
        document.head.appendChild(recaptchaScript)
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-CRY3HG283C');
    }
}
</script>

<style scoped>

</style>